// Code generated by graphql-codegen. DO NOT EDIT.
import type * as Types from './types';

import { gql } from '@apollo/client';
export type ListCheckPointSelection = { id: string, name: string, updated_by?: string | null, created_by?: string | null, time?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, testCount: string, maxScore?: number | null, maxGrade: number, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null };

export type DetailCheckPointSelection = { description?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, skills?: Record<string, unknown> | Array<unknown> | null, image_url?: string | null, time?: string | null, order: number, diamond?: number | null, id: string, name: string, updated_by?: string | null, created_by?: string | null, descriptionEn?: string | null, allowReviewAnswers: boolean, attemptsAllowed: number, testCount: string, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, maxScore?: number | null, maxGrade: number, check_point_speaking_parts: Array<{ check_point_id: string, order: number, position: number, speaking_part_id: string, speakingPart?: { id: string, partType: Types.SpeakingPartTypesEnum, name: string, time?: string | null, questions: Array<{ maxScore: number, id: string }> } | null }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null };

export type DetailSpeakingCheckPointSelection = { checkPointId: string, position: number, checkPoint: { description?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, skills?: Record<string, unknown> | Array<unknown> | null, image_url?: string | null, time?: string | null, order: number, diamond?: number | null, id: string, name: string, updated_by?: string | null, created_by?: string | null, descriptionEn?: string | null, allowReviewAnswers: boolean, attemptsAllowed: number, testCount: string, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, maxScore?: number | null, maxGrade: number, check_point_speaking_parts: Array<{ check_point_id: string, order: number, position: number, speaking_part_id: string, speakingPart?: { id: string, partType: Types.SpeakingPartTypesEnum, name: string, time?: string | null, questions: Array<{ maxScore: number, id: string }> } | null }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }, testLevel?: { id: string, name: string, slug: string } | null };

export type ListWritingPartSelection = { id: string, name: string, title?: string | null, position: number, createdAt: string, updatedAt: string, time?: string | null, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null };

export type DetailWritingPartSelection = { id: string, name: string, title?: string | null, position: number, createdAt: string, updatedAt: string, time?: string | null, images: Array<{ position: number, image: { id: string, blurHash?: string | null } }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null };

export type DetailWritingCheckPointSelection = { checkPointId: string, position: number, checkPoint: { description?: string | null, type: string, domains?: Record<string, unknown> | Array<unknown> | null, level: Record<string, unknown> | Array<unknown>, skills?: Record<string, unknown> | Array<unknown> | null, image_url?: string | null, time?: string | null, order: number, diamond?: number | null, id: string, name: string, updated_by?: string | null, created_by?: string | null, descriptionEn?: string | null, allowReviewAnswers: boolean, attemptsAllowed: number, testCount: string, nameEn?: string | null, isPremium: boolean, createdAt: string, updatedAt: string, maxScore?: number | null, maxGrade: number, check_point_speaking_parts: Array<{ check_point_id: string, order: number, position: number, speaking_part_id: string, speakingPart?: { id: string, partType: Types.SpeakingPartTypesEnum, name: string, time?: string | null, questions: Array<{ maxScore: number, id: string }> } | null }>, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }, writingParts: Array<{ id: string, name: string, title?: string | null, position: number, createdAt: string, updatedAt: string, time?: string | null, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null }>, testLevel?: { id: string, name: string } | null };

export type DetailSchoolSelection = { id: string, name: string, phone_number?: string | null, email?: string | null, status: string, address?: string | null, updated_at: string, province_id?: string | null, domain_id?: string | null, students_limit?: number | null, verified: boolean, classes: Array<{ class_students_aggregate: { aggregate?: { count: number } | null }, class_students: Array<{ learner_profile?: { user_attempts_aggregate: { aggregate?: { count: number } | null } } | null }> }>, domain?: { domain_name?: string | null, id: string, full_domain_name?: string | null } | null, province?: { name: string, id: string } | null, school_teacher_profiles: Array<{ teacher_profile: { user: { email?: string | null, id: string, phone_number?: string | null } } }> };

export type SpeakingCheckpointPart = { check_point_id: string, order: number, position: number, speaking_part_id: string, speakingPart?: { id: string, name: string, title: string, partType: Types.SpeakingPartTypesEnum, position: number, createdAt: string, updatedAt: string, time?: string | null, userCreated?: { email?: string | null } | null, userUpdated?: { email?: string | null } | null } | null };

export const ListCheckPointSelection = gql`
    fragment ListCheckPointSelection on check_points {
  id
  name
  nameEn: name_en
  isPremium: is_premium
  createdAt: created_at
  updatedAt: updated_at
  testCount: test_count
  maxScore: max_score
  maxGrade: max_grade
  updated_by
  created_by
  time
  type
  domains
  level
  userCreated {
    email
  }
  userUpdated {
    email
  }
}
    `;
export const DetailCheckPointSelection = gql`
    fragment DetailCheckPointSelection on check_points {
  ...ListCheckPointSelection
  description
  descriptionEn: description_en
  allowReviewAnswers: allow_review_answers
  attemptsAllowed: attempts_allowed
  testCount: test_count
  type
  domains
  level
  skills
  image_url
  time
  order
  diamond
  check_point_speaking_parts(order_by: {position: asc}) {
    check_point_id
    order
    position
    speaking_part_id
    speakingPart {
      questions {
        maxScore
        id
      }
      id
      partType
      name
      time
    }
  }
}
    ${ListCheckPointSelection}`;
export const DetailSpeakingCheckPointSelection = gql`
    fragment DetailSpeakingCheckPointSelection on speaking_check_points {
  checkPointId
  checkPoint {
    ...DetailCheckPointSelection
  }
  testLevel {
    id
    name
    slug
  }
  position
}
    ${DetailCheckPointSelection}`;
export const ListWritingPartSelection = gql`
    fragment ListWritingPartSelection on writing_parts {
  id
  name
  title
  position
  createdAt
  updatedAt
  time
  userCreated {
    email
  }
  userUpdated {
    email
  }
}
    `;
export const DetailWritingPartSelection = gql`
    fragment DetailWritingPartSelection on writing_parts {
  ...ListWritingPartSelection
  images(order_by: {position: asc}) {
    image {
      id
      blurHash: blur_hash
    }
    position
  }
}
    ${ListWritingPartSelection}`;
export const DetailWritingCheckPointSelection = gql`
    fragment DetailWritingCheckPointSelection on writing_check_points {
  checkPointId
  checkPoint {
    ...DetailCheckPointSelection
  }
  writingParts(order_by: {position: asc}) {
    ...ListWritingPartSelection
  }
  testLevel {
    id
    name
  }
  position
}
    ${DetailCheckPointSelection}
${ListWritingPartSelection}`;
export const DetailSchoolSelection = gql`
    fragment DetailSchoolSelection on schools {
  id
  name
  phone_number
  email
  status
  address
  updated_at
  province_id
  domain_id
  students_limit
  verified
  classes {
    class_students_aggregate {
      aggregate {
        count
      }
    }
    class_students {
      learner_profile {
        user_attempts_aggregate(where: {submitted_at: {_is_null: false}}) {
          aggregate {
            count
          }
        }
      }
    }
  }
  domain {
    domain_name
    id
    full_domain_name
  }
  province {
    name
    id
  }
  school_teacher_profiles {
    teacher_profile {
      user {
        email
        id
        phone_number
      }
    }
  }
}
    `;
export const SpeakingCheckpointPart = gql`
    fragment SpeakingCheckpointPart on check_point_speaking_parts {
  check_point_id
  order
  position
  speaking_part_id
  speakingPart {
    id
    name
    title
    partType
    position
    createdAt
    updatedAt
    time
    userCreated {
      email
    }
    userUpdated {
      email
    }
  }
}
    `;