import { useApolloClient, useMutation, useQuery, useSubscription } from '@apollo/client';
import { useRouter } from 'next/compat/router';
import { useCallback, useEffect, useMemo } from 'react';
import LoadingScreen from '@/app/LoadingScreen';
import {
  AuthProviderViewerSessionQueryQuery,
  ViewerSessionEventType,
} from '@/__generated__/graphql';
import { graphql } from '@/__generated__';
import context from './authContext';

const LearnerProfileFragment = graphql(/* GraphQL */ `
  fragment LearnerProfile on LearnerProfile {
    # id®
    id: learnerProfileId
    learnerProfileId
    firstName
    lastName
    displayName
    dateOfBirth
    gender
    avatarUrl
    isAnonymous
    star
    user {
      id
      phoneNumber
      email
      subscriptionExpiresAt
    }
  }
`);

const ViewerFragment = graphql(/* GraphQL */ `
  fragment Viewer on User {
    # id
    id: userId
    email
    phoneNumber
    emailVerified
    email
    subscriptionExpiresAt
    isSubscribing
    canCreateLearnerProfile
    diamond
    teacherVerified
    adminRole
    currentLearnerProfile {
      ...LearnerProfile
    }
  }
`);

const ViewerSessionQuery = graphql(/* GraphQL */ `
  query AuthProviderViewerSessionQuery {
    viewerSession {
      viewer {
        ...Viewer
      }
      loginSession {
        id: userSessionId
        active
        createdAt
        userAgent {
          summary
        }
        deactivatedBySession {
          id: userSessionId
          active
          createdAt
          userAgent {
            summary
          }
        }
      }
    }
  }
`);

const AuthProviderViewerLogOutMutation = graphql(/* GraphQL */ `
  mutation AuthProviderViewerLogOutMutation {
    viewerLogOut {
      userErrors {
        code
      }
    }
  }
`);

const AuthProviderViewerSessionEvent = graphql(/* GraphQL */ `
  subscription AuthProviderViewerSessionEvent {
    viewerSessionEvent {
      event
    }
  }
`);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const client = useApolloClient();

  const { data, loading, refetch } = useQuery(ViewerSessionQuery, {
    context: { v2: true },
    fetchPolicy: 'cache-and-network',
  });

  const user = data?.viewerSession.viewer;

  const currentLearnerProfileFragment = user?.currentLearnerProfile;
  const currentLearnerProfile = useMemo(
    () =>
      currentLearnerProfileFragment
        ? { ...currentLearnerProfileFragment, id: currentLearnerProfileFragment.id.toString() }
        : undefined,
    [currentLearnerProfileFragment],
  );

  useEffect(() => {
    if (user && typeof router.query.auth_redirect_url === 'string') {
      if (router.query.auth_redirect_url.startsWith('http')) {
        window.location.href = router.query.auth_redirect_url;
      } else {
        void router.replace(router.query.auth_redirect_url);
      }
    }
  }, [router, user]);

  const writeQueryViewer = useCallback(
    (viewerQuery: AuthProviderViewerSessionQueryQuery['viewerSession']) => {
      const d = { ...client.readQuery({ query: ViewerSessionQuery }) };
      if (d.viewerSession) {
        d.viewerSession = { ...d.viewerSession, ...viewerQuery };
      }
      client.writeQuery({
        query: ViewerSessionQuery,
        data: d,
      });
    },
    [client],
  );

  const [commitLogOut, { client: clientLogout }] = useMutation(AuthProviderViewerLogOutMutation, {
    context: { v2: true },
    onError(error) {
      console.error(error);
    },
    onCompleted() {
      writeQueryViewer({ viewer: null, loginSession: null });
      void clientLogout.resetStore();
    },
  });

  // TODO: rewrite this

  // TODO: remove this after migrate old exam

  const logOut = useCallback(async () => {
    await commitLogOut();
  }, [commitLogOut]);

  useSubscription(AuthProviderViewerSessionEvent, {
    context: { v2: true },
    skip: !user,
    onData({ data: { data: viewerSessionEventData } }) {
      if (viewerSessionEventData?.viewerSessionEvent) {
        // const { event } = viewerSessionEventData.viewerSessionEvent;
        // if (
        //   event === ViewerSessionEventType.NewSession ||
        //   event === ViewerSessionEventType.RestoredDiamondInAppPurchases
        // ) {
        //   return refetch();
        // }
        // return refetch();
      }
    },
  });

  const value = useMemo(() => {
    return {
      loading,
      user,
      currentLearnerProfile,

      logOut,
      writeQueryViewer,
      reFetchViewer: refetch,
      role: user?.adminRole,
    };
  }, [currentLearnerProfile, loading, logOut, refetch, user, writeQueryViewer]);

  if (loading) {
    return <LoadingScreen />;
  }

  return <context.Provider value={value}>{children}</context.Provider>;
}
