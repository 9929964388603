// components
import {
  AppleOutlined,
  AudioOutlined,
  ClusterOutlined,
  CommentOutlined,
  CrownOutlined,
  DashboardOutlined,
  FileTextOutlined,
  GlobalOutlined,
  HomeOutlined,
  MailOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  SolutionOutlined,
  SoundOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { MenuProps } from 'antd';
import Link from 'next/link';

function DotIcon() {
  return (
    <div
      style={{
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        backgroundColor: 'rgb(145, 158, 171)',
        marginRight: '8px',
      }}
    />
  );
}

const ICONS = {
  speaking: <AudioOutlined />,
  writing: <FileTextOutlined />,
  exam: <SolutionOutlined />,
  duolingo: <SolutionOutlined />,
  mail: <MailOutlined />,
  kanban: <HomeOutlined />,
  customDomains: <GlobalOutlined />,
  orders: <OrderedListOutlined />,
  dashboard: <DashboardOutlined />,
  user: <UserOutlined />,
  feature: <AppleOutlined />,
  chat: <CommentOutlined />,
  banking: <CrownOutlined />,
  setting: <ClusterOutlined />,
  tool: <ToolOutlined />,
  notification: <NotificationOutlined />,
  dotIcon: <DotIcon />,
  listen: <SoundOutlined />,
};

type MenuItem = Required<MenuProps>['items'][number];

const sidebarConfig: MenuItem[] = [
  {
    label: <Link href="/dashboard">Dashboard</Link>,
    title: 'Dashboard',
    key: 'dashboard',
    icon: ICONS.dashboard,
  },
  {
    label: 'Users',
    key: 'users',
    icon: ICONS.user,
    children: [
      {
        icon: ICONS.dotIcon,
        label: <Link href="/users/user">Users</Link>,
        key: 'user',
        title: 'Users',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/users/profiles">Learner profile</Link>,
        key: 'profiles',
        title: 'Profiles',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/users/advice">Request</Link>,
        key: 'advice',
        title: 'Request',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/users/referral">Referral</Link>,
        key: 'referral',
        title: 'Referral',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/users/ai">Ai Conversation</Link>,
        key: 'ai',
        title: 'Ai Conversation',
      },
    ],
  },
  {
    label: 'Cambridge',
    key: 'cambridge',
    icon: ICONS.exam,
    children: [
      {
        label: 'Exam',
        key: 'exam',
        icon: ICONS.exam,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/exam/testlevels">Test Levels</Link>,
            key: 'testlevels',
            title: 'Test Levels',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/exam/checkpoints">Check Points</Link>,
            key: 'checkpoints',
            title: 'Check Points',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/exam/parts">Parts</Link>,
            key: 'parts',
            title: 'Parts',
          },
        ],
      },
      {
        label: 'Speaking',
        key: 'speaking',
        icon: ICONS.speaking,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/speaking/checkpoints-speaking">Check Points</Link>,
            key: 'checkpoints-speaking',
            title: 'Check Points',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/speaking/parts-speaking">Parts</Link>,
            key: 'parts-speaking',
            title: 'Parts',
          },
        ],
      },
      {
        label: 'Writing',
        key: 'writing',
        icon: ICONS.writing,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/writing/checkpoints-writing">Check Points</Link>,
            key: 'checkpoints-writing',
            title: 'Check Points',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/writing/parts-writing">Parts</Link>,
            key: 'parts-writing',
            title: 'Parts',
          },
        ],
      },
      {
        label: 'Topic',
        key: 'topic',
        icon: ICONS.writing,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/topic/list">Danh sách Topic</Link>,
            key: 'list',
            title: 'List Topic',
          },
        ],
      },
      {
        label: 'Vocab Word',
        key: 'vocab',
        icon: ICONS.writing,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/cambridge/vocab/list">Danh sách Word</Link>,
            key: 'vocab-list',
            title: 'List Word',
          },
        ],
      },
    ],
  },

  {
    label: 'Duolingo',
    key: 'duolingo',
    icon: ICONS.duolingo,
    children: [
      {
        icon: ICONS.dotIcon,
        label: <Link href="/duolingo/checkpoints">Check Points</Link>,
        key: 'checkpoints',
        title: 'Check Points',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/duolingo/parts">Parts</Link>,
        key: 'parts',
        title: 'Parts',
      },
    ],
  },

  {
    label: 'IELTS',
    key: 'ielts',
    icon: ICONS.writing,
    children: [
      {
        label: 'Speaking',
        key: 'i-speaking',
        icon: ICONS.speaking,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-speaking/checkpoints-ielts">Check Points</Link>,
            key: 'checkpoints-ielts',
            title: 'Check Points',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-speaking/questions-ielts">Questions</Link>,
            key: 'questions-ielts',
            title: 'Questions',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-speaking/part-info-ielts">Part Info</Link>,
            key: 'part-info-ielts',
            title: 'Part Info',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-speaking/part-ielts">Part</Link>,
            key: 'part-ielts',
            title: 'Part',
          },
        ],
      },

      {
        label: 'Reading',
        key: 'i-reading',
        icon: ICONS.exam,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-reading/checkpoints-ielts-reading">Check Points</Link>,
            key: 'checkpoints-ielts-reading',
            title: 'Check Points',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-reading/questions-ielts-reading">Questions</Link>,
            key: 'questions-ielts-reading',
            title: 'Questions',
          },

          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-reading/part-ielts-reading">Part</Link>,
            key: 'part-ielts-reading',
            title: 'Part',
          },
        ],
      },

      {
        label: 'Listening',
        key: 'i-listening',
        icon: ICONS.listen,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-listening/checkpoints-ielts-listening">Check Points</Link>,
            key: 'checkpoints-ielts-listening',
            title: 'Check Points',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-listening/questions-ielts-listening">Questions</Link>,
            key: 'questions-ielts-listening',
            title: 'Questions',
          },

          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-listening/part-ielts-listening">Part</Link>,
            key: 'part-ielts-listening',
            title: 'Part',
          },
        ],
      },

      {
        label: 'Writing',
        key: 'i-writing',
        icon: ICONS.writing,
        children: [
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-writing/checkpoints-ielts-writing">Check Points</Link>,
            key: 'checkpoints-ielts-writing',
            title: 'Check Points',
          },
          {
            icon: ICONS.dotIcon,
            label: <Link href="/ielts/i-writing/part-ielts-writing">Part</Link>,
            key: 'part-ielts-writing',
            title: 'Part',
          },
        ],
      },
    ],
  },
  {
    label: 'Lesson Plan',
    key: 'lesson',
    icon: ICONS.banking,
    children: [
      {
        icon: ICONS.dotIcon,
        label: <Link href="/lesson/playlist">Play list</Link>,
        key: 'playlist',
        title: 'Play list',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/lesson/book">Book</Link>,
        key: 'book',
        title: 'Books',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/lesson/unit">Units</Link>,
        key: 'unit',
        title: 'Units',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/lesson/lesson">Lesson</Link>,
        key: 'lessonChild',
        title: 'Lesson',
      },
    ],
  },
  {
    label: 'Active Code',
    key: 'codes',
    icon: ICONS.banking,
    children: [
      {
        icon: ICONS.dotIcon,
        label: <Link href="/codes/active">Active Codes</Link>,
        key: 'active',
        title: 'Active Codes',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/codes/plan">Plan</Link>,
        key: 'plan',
        title: 'Plan',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/codes/order">Order Code</Link>,
        key: 'order',
        title: 'Order Code',
      },
      {
        label: <Link href="/codes/flyer-package">Flyer Package</Link>,
        key: 'flyer-package',
        icon: ICONS.dotIcon,
        title: 'Flyer Package',
      },
    ],
  },
  {
    label: <Link href="/discount">Discount</Link>,
    key: 'discount',
    icon: ICONS.feature,
    title: 'Discount',
  },
  {
    label: 'Center',
    key: 'center',
    icon: ICONS.kanban,
    children: [
      {
        label: <Link href="/center/school">School</Link>,
        key: 'school',
        icon: ICONS.dotIcon,
        title: 'School',
      },
      {
        label: <Link href="/center/custom-domains">Custom Domains</Link>,
        key: 'custom-domains',
        icon: ICONS.dotIcon,
        title: 'Custom Domains',
      },
    ],
  },
  {
    label: 'Notifications',
    key: 'notifications',
    icon: ICONS.notification,
    children: [
      {
        label: <Link href="/notifications/notification">Notification</Link>,
        key: 'notification',
        icon: ICONS.dotIcon,
        title: 'Notification',
      },
      {
        label: <Link href="/notifications/push-notification">Mobile Notification</Link>,
        key: 'push-notification',
        icon: ICONS.dotIcon,
        title: 'Mobile Notification',
      },
      {
        label: <Link href="/notifications/versionNotification">Version Notification</Link>,
        key: 'versionNotification',
        icon: ICONS.dotIcon,
        title: 'Version Notification',
      },
    ],
  },

  {
    label: 'Statement Result',
    key: 'statement-result',
    icon: ICONS.chat,
    children: [
      {
        icon: ICONS.dotIcon,
        label: <Link href="/statement-result/statements">Statement</Link>,
        key: 'statements',
        title: 'Statement',
      },
      {
        icon: ICONS.dotIcon,
        label: <Link href="/statement-result/comments">Comment</Link>,
        key: 'comments',
        title: 'Comment',
      },
    ],
  },

  {
    label: 'Tools',
    key: 'tools',
    icon: ICONS.tool,
    children: [
      {
        label: <Link href="/tools/theme">Themes</Link>,
        key: 'avatar-theme',
        icon: ICONS.dotIcon,
        title: 'Themes',
      },
      {
        label: <Link href="/tools/avatar/list">Avatar Frame</Link>,
        key: 'avatar-frame',
        icon: ICONS.dotIcon,
        title: 'Avatar Frame',
      },
      {
        label: <Link href="/tools/orders">Manage Orders</Link>,
        key: 'orders',
        icon: ICONS.dotIcon,
        title: 'Manage Orders',
      },
      {
        label: <Link href="/tools/hack">Tools</Link>,
        key: 'hack',
        icon: ICONS.dotIcon,
        title: 'Tools',
      },
      {
        label: <Link href="/tools/feature">Feature Flag</Link>,
        key: 'feature',
        icon: ICONS.dotIcon,
        title: 'Feature Flag',
      },
    ],
  },
];

export default sidebarConfig;
