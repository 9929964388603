import { useAuth } from '@/providers/auth';
import { values } from 'lodash-es';
import useTranslation from 'next-translate/useTranslation';
import { useSnackbar } from 'notistack';
import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/compat/router';
import { UserAdminRole } from '@/__generated__/graphql';
import { AUTH_PATH } from '@/constants';

const ListRole = values(UserAdminRole);

export default function Guard({ children }: { children: ReactNode }) {
  const { user, writeQueryViewer, logOut } = useAuth();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (
      (!user?.adminRole || (user?.adminRole && !ListRole.includes(user.adminRole))) &&
      router.pathname !== AUTH_PATH.LOGIN
    ) {
      enqueueSnackbar(t('message.login.error'), { variant: 'error', transitionDuration: 100 });
      void logOut();
      void router.replace(AUTH_PATH.LOGIN);
    }
  }, [enqueueSnackbar, logOut, router, t, user, writeQueryViewer]);

  if (
    (!user?.adminRole || (user?.adminRole && !ListRole.includes(user.adminRole))) &&
    router.pathname !== AUTH_PATH.LOGIN
  ) {
    return;
  }

  return <>{children}</>;
}
