import copy from 'copy-to-clipboard';
import { useCallback } from 'react';

export default function useCopyToClipboard(text?: string) {
  const onCopy = (text2?: string) => {
    return copy(text || text2 || '', { format: 'text/plain' });
  };

  return onCopy;
}
