import sidebarConfig from '@/layouts/main/sidebarConfig';
import { Menu } from 'antd';
import { dropRight, last } from 'lodash-es';
import { useRouter } from 'next/compat/router';
import { useCallback, useEffect, useState } from 'react';

export default function NavSection() {
  const router = useRouter();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onSelect = useCallback((params) => {
    setSelectedKeys(params.selectedKeys);
  }, []);

  const onOpenChange = useCallback((params) => {
    setOpenKeys(params);
  }, []);

  useEffect(() => {
    const listPath = router.pathname.replace('/', '').split('/');
    setSelectedKeys(last(listPath));

    if (listPath.length > 1) {
      setOpenKeys(dropRight(listPath));
    }
  }, [router.pathname]);

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      style={{ width: 256 }}
      items={sidebarConfig}
      selectedKeys={selectedKeys}
      onSelect={onSelect}
      onOpenChange={onOpenChange}
    />
  );
}
