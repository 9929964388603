import { useRouter } from 'next/compat/router';
import { useMemo, useRef, useState } from 'react';
import setLanguage from 'next-translate/setLanguage';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import Image from 'next/image';
import MenuPopover from './MenuPopover';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg',
  },
  {
    value: 'vi',
    label: 'Vietnam',
    icon: '/static/icons/ic_flag_vn.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [langSelected, setLangSelected] = useState('vi');
  const { locale } = useRouter();

  const langItem = useMemo(() => LANGS.find((el) => el.value === (locale || 'vi')), [locale]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <Image
          priority
          src={langItem?.icon || ''}
          alt={langItem?.label || ''}
          width={24}
          height={24}
        />
      </IconButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === langSelected}
              sx={{ py: 1, px: 2.5 }}
              onClick={() => {
                setOpen(false);
                void setLanguage(option.value);
                setLangSelected(option.value);
              }}
            >
              <ListItemIcon defaultValue="en">
                <Image alt={option.label} src={option.icon} width={28} height={28} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }} defaultValue="vi">
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
