export const QUERY = {
  NEXT: 'next',
  Q: 'q',
  TYPE: 'type',
  AVAILABILITY: 'availability',
  TEST_LEVEL_ID: 'testLevelId',
  PROVINCE_ID: 'provinceId',
  IS_USER_CREATED: 'is_user_created',
  DATE_CODE: 'dateCode',
  PREMIUM: 'premium',
  EXAM_TYPE: 'exam_type',
  PLAN: 'plan',
  STATUS: 'status',
  AppTypeMobile: 'appTypeMobile',
  CP_TYPE: 'cpType',
  ID: 'id',
  DISCOUNT_CODE: 'discount_code',
  DOMAIN: 'domain',
  LEVEL: 'level',
  SKILLS: 'skills',
  ROLE: 'role',
} as const;

export const STATUS = {
  ALL: 'all',
  IN: 'in',
  OUT: 'out',
} as const;

export const STATUS_SCHOOL = {
  ALL: 'all',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

export const AUTH_PATH = {
  LOGIN: '/login',
  LOGOUT: '/logout',
} as const;

export const PATH = {
  SPEAKING_CHECK_POINTS: '/speaking/checkpoints',
  WRITING_CHECK_POINTS: '/writing/checkpoints',
  LIST_SCHOOLS: '/school/schools',
} as const;
